<template>
  <div class="w-full h-fit">
    <section class="w-full p-8 mt-20 px-12 flex flex-col justify-center items-center bg-gradient-to-b from-transparent to-turquoise-500/30 text-center">
      <h3 class="sm:text-3xl text-lg text-turquoise-500 font-brand font-bold mb-6">Join Oxbull Today</h3>
      <h2 class="sm:text-5xl text-3xl text-white font-brand font-bold">Bullish Action, Bullish Results</h2>

      <!-- <div class="w-max flex justify-center items-center overflow-hidden relative">
        <div class="w-[400px] h-[400px] flex justify-center items-center relative">
          <GoldBull class="z-20"></GoldBull>
          <img class="w-[400px] h-[400px] absolute animate-spin-slow" src="/bull_text_circle.svg">
        </div>
      </div> -->

      <div class="flex justify-center items-center space-x-4 mt-12">
        <a id="stake_footer" href="/app/stake" target="_blank" class="sm:w-[180px] btn bg-white text-black text-lg hover:text-white hover:bg-white/20 font-brand font-bold border-none">Stake $OXB</a>
        <a id="footer_launch_app" href="/app" target="_blank" class="sm:w-[180px] btn bg-white/10 text-turquoise-500 text-lg font-brand font-bold hover:text-black hover:bg-turquoise-500 border-none">Launch DAPP</a>
      </div>
    </section>

    <section class="w-full p-4 flex flex-col justify-center items-center">
      <div class="w-full flex flex-col justify-center items-center  pb-[10vh]">
        <div class="w-full grid sm:grid-cols-3 grid-cols-1 gap-2">
          <div class="w-full">
            <div class="w-full bg-white/5 rounded-md p-4 border border-white/10">
              <p class="text-white font-brand font-bold mb-1 px-2 text-lg">OXB Token</p>

              <button @click="copy()" class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-between items-center group">
                <span>Address: {{ OXB_V2_ADDRESS.substring(0, 10) }}...</span>

                <div class="flex justify-center items-center space-x-2">
                  <template v-if="!copied">
                    <span class="text-zinc-300 group-hover:text-turquoise-400 text-sm">Copy</span>
                    <Icon name="mdi:content-copy" class="text-zinc-300 group-hover:text-turquoise-400 text-sm" />
                  </template>

                  <template v-else>
                    <span class="text-zinc-300 group-hover:text-turquoise-400 text-sm">Copied</span>
                    <Icon name="mdi:check" class="text-zinc-300 group-hover:text-turquoise-400 text-sm" />
                  </template>
                </div>
              </button>

              <a id="footer_buy_oxb" :href="`https://pancakeswap.finance/swap?outputCurrency=${OXB_V2_ADDRESS}`" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <img class="opacity-70 w-5 h-5 p-0.5 mr-2" src="/bunny-mono.png" alt="Pancakeswap logo" />
                  Buy on PancakeSwap
                </button>
              </a>

              <a href="https://coinmarketcap.com/currencies/oxbull-tech/" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-white opacity-70" aria-hidden="true" />
                  CoinMarketCap
                </button>
              </a>

              <a href="https://www.coingecko.com/en/coins/oxbull-tech" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <!-- <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" /> -->
                  <img class="w-5 h-5 mr-2 opacity-70" src="/cg.svg" alt="CoinGecko logo" />
                  CoinGecko
                </button>
              </a>

              <a id="footer_launch_cryptorank" href="https://cryptorank.io/fundraising-platforms/oxbull" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <!-- <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-white opacity-70 " aria-hidden="true" /> -->
                  <img class="w-5 h-5 mr-2 opacity-70" src="/symbol_white.png" alt="Cryptorank logo" />
                  Cryptorank
                </button>
              </a>

              <a href="https://chainbroker.io/platforms/oxbull" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <!-- <Icon name="simple-icons:coinmarketcap" class="text-lg mr-2 text-white opacity-70 " aria-hidden="true" /> -->
                  <div class="w-5 h-5 mr-2 flex justify-center items-center">
                    <img class="w-4 h-4 opacity-70" src="/chainbroker.png" alt="Chainbroker logo" />
                  </div>
                  Chainbroker
                </button>
              </a>

              

            </div>
          </div>

          <div class="w-full">
            <div class="w-full bg-white/5 rounded-md p-4 border border-white/10">
              <p class="text-white font-brand font-bold mb-1 px-2 text-lg">Socials</p>

              <a href="https://twitter.com/Oxbull5" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <Icon name="prime:twitter" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                  X @Oxbull5
                </button>
              </a>

              <a href="https://t.me/Oxbull_tech" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <Icon name="cib:telegram-plane" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                  Official Group
                </button>
              </a>

              <a href="https://t.me/Oxbull_tech_announcement" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <Icon name="cib:telegram-plane" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                  Announcement
                </button>
              </a>

              <a href="https://warpcast.com/oxbulltech" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <Icon name="simple-icons:farcaster" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                  Farcaster @oxbulltech
                </button>
              </a>

              <a href="https://www.instagram.com/oxbulltech/" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <Icon name="mdi:instagram" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                  Instagram @oxbulltech
                </button>
              </a>

              <a href="https://www.tiktok.com/@oxbull.tech" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <Icon name="ic:baseline-tiktok" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                  Tiktok @oxbull.tech
                </button>
              </a>
            </div>
          </div>

          <div class="w-full">
            <div class="w-full bg-white/5 rounded-md p-4 border border-white/10">
              <p class="text-white font-brand font-bold mb-1 px-2 text-lg">Read More</p>

              <a href="https://oxbull.medium.com/" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <Icon name="mingcute:medium-fill" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                  Blog on Medium
                </button>
              </a>

              <a href="https://docs.oxbull.tech/" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <Icon name="simple-icons:gitbook" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" />
                  Documentation
                </button>
              </a>

              <a href="https://oxbull.medium.com/oxbull-immunity-non-f5-edition-1db06c4f8074" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <!-- <Icon name="mingcute:medium-fill" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" /> -->
                  Oxbull Immunity
                </button>
              </a>

              <a href="/privacy" target="_blank" class="footer_link" >
                <button class="w-full text-left text-zinc-400 hover:bg-turquoise-500/10 hover:text-turquoise-500 p-2 rounded-xl duration-100 flex justify-start items-center group">
                  <!-- <Icon name="mingcute:medium-fill" class="text-lg mr-2 text-zinc-400 group-hover:text-turquoise-400" aria-hidden="true" /> -->
                  Privacy Policy
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>



import { OXB_V2_ADDRESS } from "~/wagmiConfig.js";
import { useClipboard } from "@vueuse/core";
const { copy, copied } = useClipboard({ source: OXB_V2_ADDRESS });



</script>

<style>

</style>